import { AliasPage } from '@did/das-app-modules'
import React from 'react'
import { useContext } from '@did/das-app-modules/src/layout/context'

const Index: React.FC = () => {
  const { refreshReverseInfo } = useContext()
  return <AliasPage successCallback={refreshReverseInfo} />
}

export default Index
